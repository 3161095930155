<script setup>
import {Modal} from "@ryokutech/brainkit_frontend";
import LocalesDropdownWithButton from "@/Components/Locales/LocalesDropdownWithButton.vue";

import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";

const {languageStore} = stores();
const {isOpenLanguageSwitchModal} = storeToRefs(languageStore);
</script>

<template>
    <Modal
        @close="isOpenLanguageSwitchModal = false"
        :show="isOpenLanguageSwitchModal"
    >
        <LocalesDropdownWithButton @set-active-locale="isOpenLanguageSwitchModal = false"/>
    </Modal>
</template>

