<script setup>
import {computed, ref} from "vue";
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import setLocale from "@/Services/setLocale.js";
import {InputDropdownPlus, handleFeedbackFromServer} from "@ryokutech/brainkit_frontend";
import {trans} from 'laravel-vue-i18n';
import ButtonDefault from "@/Components/Buttons/ButtonDefault.vue";

const {configStore} = stores();
const {activeLocale, activeObject, languages} = storeToRefs(configStore);

const emit = defineEmits(['set-active-locale']);
const selectedLocale = ref();

const activateLocale = computed(() => {
    return activeLocale.value;
});

async function setActiveLocale(locale) {
    await setLocale(locale);
    handleFeedbackFromServer({type: 'success', message: trans('language.ui.feedback.language_set')});
    emit('set-active-locale', locale);
}
</script>

<template>
    <InputDropdownPlus
        :close-on-select="true"
        :searchable="false"
        :clearable="false"
        :value="activateLocale"
        @select="selectedLocale = $event"
        :option-list="languages"/>


    <ButtonDefault
        class="mt-2"
        classesStyleColor="bg-brainkitGrayscale1 hover:bg-brainkitGrayscale2"
        @click="setActiveLocale(selectedLocale)"
    >
        {{ $t('ui.word.confirm') }}
    </ButtonDefault>
</template>
