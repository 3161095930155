<script setup>
import {onMounted, ref} from "vue";
import setLocale from "@/Services/setLocale.js";
import LocalesSwitchModal from "@/Components/Locales/LocalesSwitchModal.vue";

import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import {router, usePage} from "@inertiajs/vue3";
import accessCheck from "@/Services/accessCheck.js";

const {configStore} = stores();
const {activeLocale, activeObject} = storeToRefs(configStore);


onMounted(async () => {
    animate.value = true;

    await storeCompleteCheck();

    if (usePage().url === "/access/password" || usePage().url === "/access/time") {
        return
    }

    await accessCheck(function (response) {
        if (!response.data.hasAccess) {
            router.get(route('access.password'));
        }
    })
});

async function storeCompleteCheck() {
    const keysToCheck = ['question', 'object'];
    const keysExits = areKeysPresentInLocalStorage(keysToCheck);

    if (!keysExits) {
        await setLocale('', false);
    }
}

function areKeysPresentInLocalStorage(keys) {
    return keys.every(key => localStorage.getItem(key) !== null);
}


const animate = ref(false);
</script>
<template>

    <transition name="slide_effect" mode="out-in">
        <div v-if="animate">
            <slot/>
        </div>
    </transition>

    <LocalesSwitchModal/>
</template>
<style>


.slide_effect-enter-active {
    animation: slide-in 200ms ease-out forwards;
}

.slide_effect-leave-active {
    animation: slide-out 200ms ease-out forwards;
}

@keyframes slide-in {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-30px);
        opacity: 0;
    }
}
</style>
